// Used to prevent googlebot from rendering our page as blank, should be imported first
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'; // <- needed if using firestore
// import 'firebase/functions' // <- needed if using httpsCallable
import React, { Fragment, lazy, Suspense } from "react";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createFirestoreInstance } from 'redux-firestore';
import { reduxFirebase as rfConfig } from './config';
import GlobalStyles from "./GlobalStyles";
import initFirebase from './initFirebase';
// import * as serviceWorker from "./serviceWorker";
import Pace from "./shared/components/Pace";
import store from "./store";
import theme from "./theme";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { setPusherClient } from 'react-pusher';
import Pusher from 'pusher-js';
import axios from "axios";
import { appConfig } from "./config";

const PublicClientComponent = lazy(() => import("./core/components/Main"));
const stripePromise = loadStripe('pk_test_YOrTzg4Pyh5OPNKuenH5ajIU00gUG9g81H');

const pusherClient = new Pusher('4d396ece5ee4dd399490', {
      cluster: 'ap1',
      forceTLS: true
  });

initFirebase()

const checkVersion = () => {
  setTimeout(() => {
    console.log( 'app', 'checking version')

    axios.get(`${appConfig.host}/version`).then( resp => {
      console.log( 'ver', resp.data.return_msg )

      const _ver = localStorage.getItem( 'ver' ) 
      console.log( 'ver', resp.data.return_msg )

      let _reload = true

      if( _ver!==null && _ver!==undefined ) {
        if (_ver === resp.data.return_msg) {
          _reload = false
        }
      }

      if ( _reload === true ) {
        localStorage.setItem('ver', resp.data.return_msg )
        console.log( 'navBar', 'reload now ...')
        window.location.reload(true)
      }
    })  
  }, 1000*10*1 )

}

function App() {
  setPusherClient(pusherClient);
  checkVersion()

  return (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <Provider store={store}>
          <ReactReduxFirebaseProvider 
            firebase={firebase}
            config={rfConfig}
            dispatch={store.dispatch}
            createFirestoreInstance={createFirestoreInstance}>
            
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <GlobalStyles />
              <Pace color={theme.palette.primary.light} />
              <Suspense fallback={<Fragment />}>
                <Switch>
                  <Route>
                    <PublicClientComponent />
                  </Route>
                </Switch>
              </Suspense>
            </MuiThemeProvider>

          </ReactReduxFirebaseProvider>
        </Provider>
      </BrowserRouter>
    </Elements>
  );
}

// serviceWorker.unregister();

// export default withFirebase(App);
export default App
