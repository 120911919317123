// export const REG_NEW_BAG = 'REG_NEW_BAG'
export const REFRESH_BAGS = 'REFRESH_BAGS'
export const REG_LAUNDRY_BAG = 'REG_LAUNDRY_BAG'
export const DROPDOWN_LAUNDRY_BAG = 'DROPDOWN_LAUNDRY_BAG'
export const COLLECT_LAUNDRY_BAG = 'COLLECT_LAUNDRY_BAG'
export const LOGISTIC_TO_FACTORY = 'LOGISTIC_TO_FACTORY'
export const FACTORY_RECEIVED = 'FACTORY_RECEIVED'
export const LOGISTIC_TO_SHOP = 'LOGISTIC_TO_SHOP'
export const SHOP_RECEIVED = 'SHOP_RECEIVED'
export const PICKUP_LAUNDRY_BAG = 'PICKUP_LAUNDRY_BAG'
export const REFRESH_READY_BAG = 'REFRESH_READY_BAG'
export const REFRESH_COLLECTED_BAG = 'REFRESH_COLLECTED_BAG'
export const REFRESH_BAG_STATE = 'REFRESH_BAG_STATE'
