import { REG_LAUNDRY_BAG, SIGN_IN, SIGN_OUT, UPDATE_PROFILE, LOAD_LOCAL_STORAGE, LOAD_LOCAL_MEMBER_INFO } from '../types';

const initialState = {
    account_state: '',  // guest, new, verified
    uid: '',
    displayName: '',
    photoURL: '',
    email: '',
    emailVerified: '',
    phoneNumber: '',
    servicePlan: '',    // one_off_payment, one_bag_monthly, ... etc.
    // createDt: '',
    refreshToken: '',
    isMerchant: false,
    isMember: false,
    isLogistic: false,
    userCode: '',
    roleId: 0,
    stripeCustId: ''
};

export default (state = initialState, action ) => {

    const {type, payload} = action;

    switch (type) {
        case LOAD_LOCAL_STORAGE:
        case LOAD_LOCAL_MEMBER_INFO:
            {
                const lsState = localStorage.getItem('membership')
                if ( lsState === null ) 
                    return initialState

                return JSON.parse( lsState )
            }
        case SIGN_IN:            
            {
                const _membership = { ...state, 
                    uid: payload.uid, 
                    displayName: payload.displayName,
                    email: payload.email,
                    emailVerified: payload.emailVerified,
                    phoneNumber: payload.phoneNumber,
                    refreshToken: payload.refreshToken,
                    isMerchant: payload.isMerchant, 
                    isMember: payload.isMember,
                    isLogistic: payload.isLogistic,
                    userCode: payload.userCode,
                    roleId: payload.roleId
                };

                localStorage.setItem( 'membership', JSON.stringify(_membership) )
                return _membership
            }
        case SIGN_OUT:
            localStorage.removeItem( 'membership' )
            return initialState;

        case UPDATE_PROFILE:
            {
                // const _membership = { ...state, phoneNumber: payload.phone, 
                //     email: payload.email, emailVerified: false,
                //     displayName: payload.displayName
                // };

                const _membership = { ...state }

                if (payload.email!==null && payload.email!==undefined) {
                    _membership['email'] = payload.email
                    _membership['emailVerified'] = false
                }

                if (payload.displayName!==null && payload.displayName!==undefined) {
                    _membership['displayName'] = payload.displayName
                }

                localStorage.setItem( 'membership', JSON.stringify(_membership) )

                return _membership
            }
        case REG_LAUNDRY_BAG:
            {
                localStorage.setItem( 'bags', JSON.stringify(payload.bags) )
        
                return { ...state, bags: payload.bags }
            }
        default:
            return state;
    }
}