import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { getFirebase } from 'react-redux-firebase'

// const initialState = {};
const initialState = window && window.__INITIAL_STATE__ // set initial state here

const middleware = [
  thunk.withExtraArgument({ getFirebase })  
];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
