export const firebase = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
};

export const reduxFirebase = {
  userProfile: "users",
  useFirestoreForProfile: true,
  enableLogging: false,
};

export const planDefinitions = {
  oneTimePaymentHKD: 88,
  monthlyPaymentHKD: 398,
  upgradePaymentHKD: 198,
  startupPaymentHKD: 198,
  planId: 'plan_HCOccB1zON9jEw'
};

export const appConfig = {
  firebase_config: {
    projectId: "d24laundry-prod",
  },
  firebase_providers: ["phone"],
  firebase_region: "asia-east2",
  host: process.env.REACT_APP_target_host,
  default_lang: "en",
};

export default { firebase, reduxFirebase, planDefinitions, appConfig };
