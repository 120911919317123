import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import { PusherProvider } from 'react-pusher-hoc';
// import Pusher from 'pusher-js';

// const pusherClient = new Pusher('4d396ece5ee4dd399490', {
//       cluster: 'ap1',
//       forceTLS: true
//   });

// ReactDOM.render(  
//     <PusherProvider value={pusherClient}>
//         <App />
//     </PusherProvider>, document.getElementById('root'));

import * as Sentry from '@sentry/browser';

Sentry.init({dsn: "https://78a294c974d248ff8e3b499399b6f9c4@o390166.ingest.sentry.io/5231207"});

ReactDOM.render( 
        <App />, document.getElementById('root'));


// ReactDOM.render(
//     <FirebaseContext.Provider value={new Firebase()}>
//       <App />
//     </FirebaseContext.Provider>,
//     document.getElementById('root'),
//   );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
