import { 
    // REG_NEW_BAG, 
    REFRESH_BAGS, 
    COLLECT_LAUNDRY_BAG, 
    LOGISTIC_TO_FACTORY, 
    FACTORY_RECEIVED, 
    LOGISTIC_TO_SHOP, 
    PICKUP_LAUNDRY_BAG, 
    SHOP_RECEIVED, 
    DROPDOWN_LAUNDRY_BAG, 
    SIGN_OUT,
    LOAD_LOCAL_STORAGE,
    REFRESH_BAG_STATE} from '../types';

const initialState = {
    bags: [
        {
        //     bagId: '1234567890',
        //     createDt: '2020-03-25T05:11:07.853Z',
        //     shortCode: 'D24BAGQHzTaDXOc',
        //     state: 'READY',
        //     uid: '6OkeRKzoudMAdUi1CVR0mDu6S5H2',
        //     // shopId: 'KT001'
        }
    ]
};

export default (state = initialState, action ) => {

    const {type, payload} = action;

    switch (type) {
        case LOAD_LOCAL_STORAGE:
            {
                const lsState = localStorage.getItem('bags')
                if ( lsState === null || lsState===undefined || lsState==='undefined' ) {
                    return initialState
                }
                return { bags: JSON.parse( lsState )}
            }
        // case REG_NEW_BAG:
        //     return { ...state, orders: payload.orders }
        case SIGN_OUT:
            localStorage.removeItem( 'bags' )
            return initialState;
        case REFRESH_BAG_STATE:
            // console.info( 'inside REFRESH_BAGS')
            // console.dir( payload.bags )
            {
                const _newBags = state.bags.map( s => {
                    if( s.bagId === payload.bagId ) {
                        s.state = payload.state
                    } 
                    return s
                })
                
                localStorage.setItem( 'bags', JSON.stringify(_newBags))
                return { bags: _newBags }
            }
        case REFRESH_BAGS:
            console.log( 'inside REFRESH_BAGS', payload.bags)
            // console.dir( payload.bags )

            localStorage.setItem( 'bags', JSON.stringify(payload.bags))
            return { bags: payload.bags }
                
        case DROPDOWN_LAUNDRY_BAG:
            // bag state change
            // payload.bagId
            // switch to 'ASSIGNED'
            { 
                const _newBags = state.bags.map( s => {
                    if( s.bagId === payload.bagId ) {
                        s.state = 'ASSIGNED'
                    } 
                    return s
                })
                localStorage.setItem( 'bags', JSON.stringify(_newBags))
                return { bags: _newBags }
            }
        case COLLECT_LAUNDRY_BAG:
            // payload.bagId
            // switch to 'COLLECTED'
            {                
                const _newBags = state.bags.map( s => {
                    if( s.bagId === payload.bagId ) {
                        s.state = 'COLLECTED'
                    } 
                    return s
                })
                localStorage.setItem( 'bags', JSON.stringify(_newBags))
                return { bags: _newBags }
            }
        case LOGISTIC_TO_FACTORY:
            // payload.bagId
            // switch to 'TO_FACTORY'
            {                
                const _newBags = state.bags.map( s => {
                    if( s.bagId === payload.bagId ) {
                        s.state = 'TO_FACTORY'
                    } 
                    return s
                })
                localStorage.setItem( 'bags', JSON.stringify(_newBags))
                return { bags: _newBags }
            }
        case FACTORY_RECEIVED:
            // payload.oid
            // switch to 'WASHING'
            {                
                const _newBags = state.bags.map( s => {
                    if( s.bagId === payload.bagId ) {
                        s.state = 'WASHING'
                    } 
                    return s
                })
                localStorage.setItem( 'bags', JSON.stringify(_newBags))
                return { bags: _newBags }
            }            
        case LOGISTIC_TO_SHOP:           
            // payload.oid
            // switch to 'TO_SHOP'
            {                
                const _newBags = state.bags.map( s => {
                    if( s.bagId === payload.bagId ) {
                        s.state = 'TO_SHOP'
                    } 
                    return s
                })
                localStorage.setItem( 'bags', JSON.stringify(_newBags))
                return { bags: _newBags }
            }

        case SHOP_RECEIVED:
            // payload.oid
            // switch to 'READY'
            {                
                const _newBags = state.bags.map( s => {
                    if( s.bagId === payload.bagId ) {
                        s.state = 'READY'
                    } 
                    return s
                })
                localStorage.setItem( 'bags', JSON.stringify(_newBags))
                return { bags: _newBags }
            }
        case PICKUP_LAUNDRY_BAG:
            // payload.oid
            // switch to 'EMPTY'   
            {                
                const _newBags = state.bags.map( s => {
                    if( s.bagId === payload.bagId ) {
                        s.state = 'EMPTY'
                    } 
                    return s
                })
                localStorage.setItem( 'bags', JSON.stringify(_newBags))
                return { bags: _newBags }
            }
        default:
            return state;
    }
}