import { COLLECT_LAUNDRY_BAG, REFRESH_ORDERS, PICKUP_LAUNDRY_BAG, SIGN_OUT, LOAD_LOCAL_STORAGE, ADD_ORDER } from '../types';

const initialState = {
    orders: [
        {
            // shortCode: 'D24Oxxxx',
            // createDt: '2020-03-24T13:17:23.989Z',
            // state: 'NEW', // e.g., 'NEW', 'PAID', 'ACCEPTED', 'DONE'
            // bagId: 'D24BAGxxx',
            // acceptDt: '2020-03-24T13:17:23.989Z',
            // shopId: 'D24SHOPxxx'
        }
    ],
    readyOrders: [],
    collectedOrders: []
};

export default (state = initialState, action ) => {

    const {type, payload} = action;

    switch (type) {
        case LOAD_LOCAL_STORAGE:
            {
                const lsState = localStorage.getItem('orders')
                if ( lsState === null || lsState===undefined || lsState==='undefined') {
                    return initialState
                }
                return JSON.parse( lsState )
            }
        // case SIGN_IN:
        //     return { ...state }
        case SIGN_OUT:
            localStorage.removeItem( 'orders' )
            return initialState;
        // case SEND_LAUNDRY_BAG:
        //     // payload.oid
        //     // switch to 'ASSIGNED'
        //     return { ...state, orders: payload.orders }
            // payload.oid
            // switch to 'NEW'

        case COLLECT_LAUNDRY_BAG:
            // payload.oid
            // switch to 'ACCEPTED'
            return { ...state, orders: payload.orders }
           
        case PICKUP_LAUNDRY_BAG:
            // payload.oid
            // switch to 'DONE'
            return state;

        case ADD_ORDER: {
            state.orders.push(payload.order);
            state.orders.sort( (a, b) => {
                return ( b.creationTime - a.creationTime )
            })
            localStorage.setItem( 'orders', JSON.stringify( state ));
            return state;
        }            
        case REFRESH_ORDERS:
            {
                const _orders = { ...state, orders: payload.orders }
                localStorage.setItem( 'orders', JSON.stringify( _orders ))
                return _orders;
            }
        default:
            return state;
    }
}