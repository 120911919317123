import { 
    SIGN_OUT,
    LOAD_LOCAL_STORAGE,
    REFRESH_PLANS,
    REFRESH_VOUCHERS,
    USE_VOUCHER,
    CANCEL_SUBS,
    AWARDED_VOUCHERS} from '../types';

const initialState = {
    plans: [
        // {
            // id: 'plan_HCOccB1zON9jEw',
            // planId: 'plan_HCOccB1zON9jEw',
            // name: '「一袋任洗」月費套餐',
            // periodEnd: 1588422924,
            // periodStart: 1588336524
            // price: config.monthlyPaymentPriceHKD,
            // billCycle: 'monthly',
            // subsId: 'sub_xxxx',
            // quantity: 1,
            // price: 100
        // }
    ],
    vouchers: [
        // {
        //     id: 'yyyyyyyyy',
        //     bagId: 'D24BAG000001',
        //     state: 'active',
        //     expireDt: '2020-05-14T04:20:29.722Z'
        // },
        // {
        //     id: 'zzzzzzzzz',
        //     bagId: 'D24BAG000002',
        //     state: 'active',
        //     expireDt: '2020-05-14T04:20:29.722Z'
        // }
    ]
};

export default (state = initialState, action ) => {

    const {type, payload} = action;

    switch (type) {
        case USE_VOUCHER:
            {
                const _newVList = state.vouchers.map( v => {
                    if( v.id === payload.vid ) {
                        v.state = 'used'
                    }
                    return v
                })
                localStorage.setItem( 'vouchers', JSON.stringify(_newVList))
                return { ...state, vouchers: _newVList }
            }
        case AWARDED_VOUCHERS:
            {
                if ( payload.vouchers !== undefined ) {
                    payload.vouchers.forEach(v => {
                        state.vouchers.push( v )                        
                    })
                }
                localStorage.setItem( 'vouchers', JSON.stringify(state.vouchers))
                return state
            }
        case REFRESH_VOUCHERS:
            {
                localStorage.setItem( 'vouchers', JSON.stringify(payload.vouchers))
                return { ...state, vouchers: payload.vouchers}
            }
        case REFRESH_PLANS:
            {
                localStorage.setItem( 'plans', JSON.stringify(payload.plans))
                return {...state, plans: payload.plans}
            }
        case CANCEL_SUBS:
            {
                const _newPlans = state.plans.map( p => {
                    if( p.subsId === payload.subsId ) {
                        p.state = 'inactive'
                    }
                    return p
                })
                console.log( 'cancelSubs', _newPlans )
                localStorage.setItem( 'plans', JSON.stringify(_newPlans))
                return { ...state, plans: _newPlans }
            }
        case LOAD_LOCAL_STORAGE:
            {
                const lsPlanState = localStorage.getItem('plans')
                let _newState = initialState
                
                if ( lsPlanState !== null || lsPlanState===undefined || lsPlanState==='undefined') {
                    _newState.plans =  JSON.parse( lsPlanState )
                }

                const lsVoucherState = localStorage.getItem('vouchers')
                if (lsVoucherState !== null ) {
                    _newState.vouchers = JSON.parse( lsVoucherState )
                }

                return _newState;
            } 
        case SIGN_OUT:
            localStorage.removeItem( 'plans' )
            return initialState;
        default:
            return state;
    }
}