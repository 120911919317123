import { combineReducers } from 'redux';
import membership from './membership'
import orders from './orders'
import bags from './bags'
import plans from './plans'
import { firebaseReducer } from 'react-redux-firebase'
// import { firestoreReducer } from 'redux-firestore'

export default combineReducers({
    firebase: firebaseReducer,
    // firestore: firestoreReducer,
    memberState: membership,
    orderState: orders,
    bagState: bags,
    planState: plans
  });